import React from 'react'
import './GridItem.scss'

const GridItemReversed = ({ title, text, children, content }) => {
  return (
    <div
      className="grid-item"
      data-sal="fade"
      data-sal-duration="2000"
      data-sal-easing="ease"
    >
      <div className="col">{content}</div>
      <div className="col inner">
        <div className="inner">
          <div className="flex flex-col max-w-lg container gap-6">
            <div>
              <h2>{title}</h2>
              <p>{text}</p>
            </div>
            <div className="justify-end lg:justify-start">{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GridItemReversed
