import React from 'react'
import './GridItem.scss'

const GridItem = ({ title, text, children, content }) => {
  return (
    <div
      className="grid-item"
      data-sal="fade"
      data-sal-duration="2000"
      data-sal-easing="ease"
    >
      <div className="col inner order-2 lg:order-1">
        <div className="inner">
          <div className="flex flex-col max-w-lg container gap-6">
            <div>
              <h2>{title}</h2>
              <p>{text}</p>
            </div>
            <div className="justify-end lg:justify-start">{children}</div>
          </div>
        </div>
      </div>
      <div className="col order-1 lg:order-2">{content}</div>
    </div>
  )
}

export default GridItem
