import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import Grid from "../components/Grid/Grid";
import GridItem from "../components/Grid/GridItem";
import GridItemReversed from "../components/Grid/GridItemReversed";
import FluidImage from "../components/FluidImage/FluidImage";

const varHall = ({ data: { page } }) => {
  const gridItems = page.var_hall.gridImages;
  return (
    <Layout>
      <Seo title={"Om hallen"} />
      <div className="h-[60px]"></div>
      <Grid>
        {gridItems.map(({ text, rubrik, bild }, index) => {
          return index % 2 ? (
            <GridItemReversed
              key={index}
              title={rubrik}
              text={text}
              content={<FluidImage src={bild.localFile} title={rubrik} />}
            />
          ) : (
            <GridItem
              key={index}
              title={rubrik}
              text={text}
              content={<FluidImage src={bild.localFile} title={rubrik} />}
            />
          );
        })}
      </Grid>
    </Layout>
  );
};

export default varHall;

export const pageQuery = graphql`
  query VarHallQuery {
    page: wpPage(id: { eq: "cG9zdDo3MzU=" }) {
      id
      var_hall {
        gridImages {
          text
          rubrik
          bild {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
